.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loading-screen h2 {
    color:white;
    font-size: 2rem;
    margin-bottom: 20px;
}

.loading-screen p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: white;
}

.loading-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid #f0f0f0;
    border-top: 10px solid #007bff;
    animation: spin 2s linear infinite;
    position: relative;
}

.loading-circle span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

span {
    color:white;
}