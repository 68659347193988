.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1e1e2f;
}

.content {
    text-align: center;
    color: #fff;
    max-width: 600px;
    background-color: #2e2e4e;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

h1 {
    font-size: 2.5rem;
    font-weight: 800;
    color: #ffffff;
}

p {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 20px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    flex-direction: column;
    align-items: flex-start;
}

.form-group label {
    margin-bottom: 5px;
    font-size: 1rem;
}

.signup-form input {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    border-radius: 5px;
    border: none;
}

.signup-form .submit-button {
    padding: 15px;
    font-size: 1.2rem;
    background-color: #4a00e0;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signup-form .submit-button:hover {
    background-color: #6a00ff;
}

.error-message {
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.loading-modal-content {
    background-color: #2e2e4e;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #4a00e0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

input[type="text"], input[type="email"], input[type="password"] { 
    color: black !important;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
