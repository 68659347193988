/* Customization.css */

.customization-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
  width: 100%;
  max-width: 800px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  color: #495057;
  margin-bottom: 8px;
}

input[type="text"],
input[type="color"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="checkbox"] {
  margin-right: 8px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
}

.assistant-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.submit-btn {
  margin-top: 10px;
}

.update-btn {
  background-color: #28a745;
  margin-top: 20px;
}

.update-btn:hover {
  background-color: #218838;
}

input[type="color"] {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  margin-right: 10px;
}

input[type="text"][value*="#"] {
  width: calc(100% - 60px);
  display: inline-block;
  margin-left: 10px;
}
