/* AdminPortal.css */

form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

input[type="text"],
input[type="email"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus {
    border-color: #4CAF50; /* Green border on focus */
    outline: none;
}

button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #4CAF50; /* Green button */
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

button[type="submit"]:hover {
    background-color: #45a049; /* Darker green on hover */
}

p {
    margin: 12px 0;
    font-size: 14px;
    text-align: center;
}

p.error {
    color: red;
}

p.success {
    color: green;
}

/* Center the form on the page */
body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f3f3f3;
}
