/* Ensure the container takes full height */
.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  /* Full viewport height */
    background-color: #1e1e2f;  /* Example background color */
  }
  
  .content {
    text-align: center;
    color: #fff;
    background-color: #2e2e4e;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  h1 span {
    color: #00b8ff;
  }
  
  p {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 40px;
    color: #ffffff;
    font-weight: 400;
  }
  
  .website-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;  /* Add margin below the form */
    flex-wrap: wrap; /* Ensure fields stack on small screens */
  }
  
  .website-form input {
    padding: 15px;
    font-size: 1.1rem;
    width: 300px;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    margin-bottom: 10px; /* Add margin for spacing on small screens */
  }
  
  .website-form button {
    padding: 15px 25px;
    font-size: 1.2rem;
    background-color: #4a00e0;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .website-form button:hover {
    background-color: #6a00ff;
  }
  
  .auth-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .toggle-signup-button, .toggle-login-button {
    padding: 10px 20px;
    font-size: 1.1rem;
    background-color: #4a00e0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-signup-button:hover, .toggle-login-button:hover {
    background-color: #6a00ff;
  }
  
  h1 {
    font-size: 3rem !important;
    font-weight: 800 !important;
    line-height: 1.2 !important;
    color: white;
  }

  .back-to-chat-button, .buy-now-button {
    padding: 15px 25px !important;
    font-size: 1.2rem !important;
    background-color: #4a00e0 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    margin-right: 20px;
  }

  /* src/pages/NotFound/NotFound.css */
.notfound-container {
  text-align: center;
  margin-top: 100px;
}

.notfound-container h1 {
  font-size: 2.5rem;
}

.back-home-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
}

.back-home-button:hover {
  background-color: #0056b3;
}

.buy-now-button {
  margin-top: 20px;

}