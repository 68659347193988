/* Ensure the container takes full height */
.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Full viewport height */
  background-color: #1e1e2f;  /* Example background color */
}

.content {
  text-align: center;
  color: #fff;
  max-width: 800px;
  background-color: #2e2e4e;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

h1 {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2;
}

h1 span {
  color: #00b8ff;
}

p {
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #ffffff;
  font-weight: 400;
}

.website-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;  /* Add margin below the form */
  flex-wrap: wrap; /* Ensure fields stack on small screens */
}

.website-form input {
  padding: 15px;
  font-size: 1.1rem;
  width: 300px;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
  margin-bottom: 10px; /* Add margin for spacing on small screens */
  color: black;
}

.website-form button {
  padding: 15px 25px;
  font-size: 1.2rem;
  background-color: #4a00e0;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.website-form button:hover {
  background-color: #6a00ff;
}

.auth-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.toggle-signup-button, .toggle-login-button, .back-to-home-button {
  padding: 10px 20px;
  font-size: 1.1rem;
  background-color: #4a00e0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-signup-button:hover, .toggle-login-button:hover, .back-to-home-button:hover {
  background-color: #6a00ff;
}

.back-to-home-button {
  margin-top: 20px;
}

/* MOBILE-RESPONSIVE DESIGN */

/* Media queries for small screens */
@media screen and (max-width: 768px) {
  .app-container {
    height: auto;  /* Adjust height on smaller screens */
  }

  .content {
    max-width: 100%; /* Take full width on small screens */
    padding: 20px;   /* Reduce padding on smaller screens */
    border-radius: 5px; /* Reduce border radius on small screens */
  }

  h1 {
    font-size: 2.2rem;  /* Reduce font size on small screens */
  }

  p {
    font-size: 1rem;  /* Adjust text size for mobile */
  }

  .website-form {
    flex-direction: column;  /* Stack the input and button vertically */
    align-items: stretch;    /* Ensure they take up full width */
  }

  .website-form input {
    width: 100%;  /* Make the input field full width on mobile */
    margin-right: 0; /* Remove right margin on smaller screens */
  }

  .website-form button {
    width: 100%;  /* Make the button full width on mobile */
  }

  .auth-buttons {
    flex-direction: column; /* Stack the buttons vertically on mobile */
    gap: 10px; /* Reduce gap between buttons */
  }

  .toggle-signup-button, .toggle-login-button, .back-to-home-button {
    width: 100%;  /* Make the buttons full width */
    font-size: 1rem;  /* Reduce font size on mobile */
  }
}
